.top_OPC_head{
    font-size: 35px !important;
    font-weight: 700 !important;
    /* padding-left: 5vh; */
    color: var(--whiteColor);
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;

}
.cont_top_OPC{
    background:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6));
    margin-top: 55vh !important;
    min-height: 200px;

}
.top_OPC_text{
    color: var(--whiteColor);
    font-size: 26px !important;
    text-align: center;
    padding-top: 10px;
    line-height: 2 !important;
}   
