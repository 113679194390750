.topS_bg{
    height: auto;
    min-height: 78vh !important;
    width: 100% !important;
    background:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('../../../images/services-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    align-content: center;
}
.bgS_text{
    padding-top: 38vh !important;
    color: white;
    padding: 30px;
    font-weight: 600 !important;
    font-size: 45px !important;

    text-align: center;
}
.bgS_text1{
    padding-top: 38vh !important;
    color: white;
    padding: 30px;
    font-weight: 600 !important;
    font-size: 45px !important;

    text-align: center;
}

@media (max-width:500px) {
    .bgS_text {
 
        padding: 10px;
        font-size: 21px !important;
        line-height: 2 !important;
      
    }
    .bgS_text1 {
 
        padding: 10px;
        font-size: 19px !important;
        line-height: 2 !important;
      
    }
    
}