.contact_bg{
    background: linear-gradient(45deg,rgba(0,0,0,0.5),transparent),url('../../images/contactK.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 550px;
}
.contact_head{
    text-align: center;
    font-size: 19px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    color: var(--redColor);
}
.contact_subhead{
    font-size: 25px !important;
    color: var(--redColor);
    font-weight: 600 !important;
    text-align: center;
    padding-bottom: 20px;

}
.btn_contact{
    width: 95% !important;
    font-weight: 600 !important;
    background: var(--redColor) !important;

}
.iconC{
    font-size: 45px;
    color: var(--redColor);
    padding-bottom: 5px;

}
.iconC_head{
    font-size: 25px !important;
    color: var(--blackColor);
    font-weight: 600 !important;
    padding-bottom: 5px;
}
.iconC_text{
    font-size: 18px !important;
    color: var(--blackColor);
}
.ContactUs_Map {
    width: 100%;
    height: 390px;
}


@media (max-width:500px) {
    .contact_bg {
        height: 500px;
        background-position: right;
    }
}
