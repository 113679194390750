/* @import "../../MainCSS.css"  */
.download_chip{
    width: 10%;
    margin-left: 25px !important;
    margin-bottom: 20px !important;
    background-color: var(--redColor) !important;
    color: white !important;
    cursor: pointer !important;
}
@media (max-width: 1200px) {
    .D6_FE_DD {
        height: 150px ;
    }
   
}
@media (max-width: 1200px) {
    .download_chip{
        width: 30%;
   
    }
}