.bt_OPC{
    background-color: var(--whiteColor);
    margin-bottom: 40px !important;
}
.bt_OPC_head{
    font-size: 30px !important;
    padding-top: 20px;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
    padding-bottom: 5px !important;
    border-bottom: solid;
    color: var(--redColor);
    width: 60%;
    border-bottom-color: var(--blackColor);
}
.bt_OPC_head2 {
    font-size: 21px !important;
    padding-top: 20px;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
    padding-bottom: 5px !important;
    border-bottom: solid;
    color: var(--redColor);
    width: 95%;
    border-bottom-color: var(--blackColor);
}
.bt_OPC_text{

    font-size: 16px !important;
    padding-top: 15px;
    text-align: justify;}

    .stat_text{
        font-weight: 600 !important;
    }
    .li_OPC{
        padding: 10px;
        font-size: 16px !important;
        text-align: justify;
    }
    .bt_OPC_head_UL{
        font-size: 28px !important;
        width: 60%;
        padding-top: 20px;
        margin-bottom: 10px !important;
        font-weight: 600 !important;
        padding-bottom: 5px !important;
        border-bottom: solid;
        color: var(--redColor);
        border-bottom-color: var(--blackColor);
    }
    .ul_OPC{
        list-style: circle;
    }

    .imgsub_OPC{
        height: 370px;
        width: 100%;
        padding: 10px;
        border-radius: 20px;
        padding: 10px;
    }
    .text_click{
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: 600 !important;
        cursor: pointer;
    }
    .text_quote{
        font-weight: 600 !important;
        font-style: italic;
    }

    @media (max-width:500px) {
        .bt_OPC_head {
            
            width: 90%;
        }
        .bt_OPC_head_UL {
            
            width: 90%;
             }
             .imgsub_OPC {
                height: 370px;
                width: 100%;
                padding: 0px;
                border-radius: 20px;
            
            }
        
    }