.mainContS{
margin-top: 20px;
}

.icon_services{
    border: 5px solid #f2f2f2;
    border-radius: 50%!important;
    /* height: 50px; */
    font-size: 32px;
    padding: 1rem;
    background: #f2f2f2f2;
    color: var(--redColor);
}
.textS_head{
    font-size: 20px !important;
    font-weight: 600 !important;
    padding-top: 10px;
    color: var(--blackColor);
    text-align: center;
}

.textS_text{
    text-align: justify;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 17px !important;
}
.sub_contS{
    padding:10px;
    margin: 10px;

}
.sub_cont1S:hover .sub_contS{
    margin: 10px;
    background-color: #f2f2f2;
    border-radius: 20px;
    cursor: pointer;
}
.sub_contS:hover{
    background-color: #f2f2f2;
    border-radius: 20px;
}
.services_mainHead{
    font-size: 25px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-weight: 600 !important;
    color: var(--redColor);
    border-bottom-style: solid;
    border-bottom-color: black;
}
.contact{
    font-size: 26px !important;
    color: var(--blackColor);
    font-weight: 600 !important;
}
.contact_chip{
    background-color: var(--redColor) !important;
color: white !important;
cursor: pointer !important;
}
.contact_block{
    margin-top: 50px;
    margin-bottom: 20px;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--blackColor);
    padding-top: 40px;
}
@media (max-width:500px) {
    .contact {
        font-size: 26px !important;
        
        font-weight: 600 !important;
        padding-bottom: 20px;
    }
}