
.img_home{
    height: auto;
    min-height: 78vh !important;
    width: 100% !important;
    background:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('../../../images/home3.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    align-content: center;
}

.home_headT{
    padding-top: 34vh;
    color: var(--whiteColor);
    text-align: center;
    font-weight: 600 !important;
    font-size: 45px !important;

}
.home_textT{
    padding-top: 5vh;
    text-align: center;
    font-size: 45px !important;
    color: var(--whiteColor);

}
.home_headT2{
    padding-top: 5vh;
    font-size: 35px !important;
    text-align: center;
    color: var(--redColor);
    font-weight: 600 !important;
}
.home_textT2{
    padding-top: 2vh;
    font-size: 17px !important;
    text-align: justify;
    color: var(--blackColor);
}