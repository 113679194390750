.D6_SL_Navbar_Items {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    /* background-color: rgba(99,192,214,0.3); */
    background: rgba(0,0,0,0.6);    /* color: black; */
   position: fixed; 
   margin-top: 70px;
    top: 0;
    z-index: 101;
    border-bottom-style: solid;
    border-bottom-color: var(--redColor);
    border-bottom-width: 2px;

}
.D6_SL_Navbar_ItemsA {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    /* background-color: rgba(99,192,214,0.3); */
       background:var(--blackColor);
    /* color: black; */
   position: fixed; 
   margin-top: 70px;
    top: 0;
    z-index: 101;
    border-bottom-style: solid;
    border-bottom-color:  var(--redColor);
    border-bottom-width: 2px;

}
.header_strip{
    background:var(--whiteColor);
    height: 70px;
    z-index: 1;
    width: 100%;
    position: fixed;
}
.icon-close-btn1{
    left: 184px;
    top: -27px;
}
.Grid_MC{
    height: 100%;
    align-content: center;
    justify-content: flex-end;
}
.icon_nav{
color: var(--redColor) !important;

font-size: 22px !important;
padding-left: 5px;
padding-right: 5px;
}
.text_icon{
    color: var(--blackColor) !important;

    font-size: 16px !important;
    padding-left: 5px;
padding-right: 25px;
font-weight: 500 !important;
}
.D6_SL_Navbar_Items1 {
    width: 100%;
    /* height: 70px; */
    /* margin-top: 100px; */
    display: flex;
    justify-content: space-between;
    /* background-color: rgb(99,192,214,0.3); */
    color: #ffffff;
   /* position: fixed;  */
    /* top: 0; */
    z-index: 101;
}
/* .gird_nav{
    justify-content: flex-end !important;
    padding-right: 37px;
} */

.D6_SL_Navbar_Items1_Image {
    width: 200px;
    height: 80px;
    /* padding: 10px 0px; */
    margin-top: 5px;
    margin-left: 20px;
    text-decoration: none;
    color: white;
}
.multi_icons{
    padding: 27px;
    font-size: 20px;
    color: black !important;
}
.multi_icons:hover{
    color: white ;
    cursor: pointer;
}
.grid_multi{
    text-align: right;
}
.grid_cont{
    /* padding-top: 30px; */
    padding-left: 15px;
}
.grid_contSub{
    display: none !important;
}
.D6_SL_Navbar_Link1{
    
    color:#ffff !important;

    text-decoration: none;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    padding-bottom: 5px;

}
.dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdownA {
    position: relative;
    
  }
  .dropdown1 {
    position: relative;
    /* display: inline-block; */
  }
  .dropdown2 {
    position: relative;
    /* display: inline-block; */
  }
  .dropdown3 {
    position: relative;
    /* display: inline-block; */
  }




  .dropdown-content {
    margin-top: 5px;
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropdown-contentA{
    margin-top: 5px;
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropdown-content1{
    display: none !important;
    margin-block-end: 100px;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    left: 100%;
    top:0px;
  }
  .dropdown-content2{
    display: none !important;
    margin-block-end: 100px;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    left: 100%;
    top:0px;
  }
  .dropdown-content3{
    display: none !important;
    margin-block-end: 100px;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    left: 100%;
    top:0px;
  }
  .dropdown-content1 a {
    color: var(--blackColor);
    cursor: pointer;
    padding: 12px 16px;
    text-decoration: none;
    font-size: 13px !important;
    display: block;
    text-align: start
  }
  .dropdown-content2 a {
    color: var(--blackColor);
    cursor: pointer;
    padding: 12px 16px;
    text-decoration: none;
    font-size: 13px !important;
    display: block;
    text-align: start
  }
  .dropdown-content3 a {
    color: var(--blackColor);
    cursor: pointer;
    padding: 12px 16px;
    text-decoration: none;
    font-size: 13px !important;
    display: block;
    text-align: start
  }
  .dropdown-content a {
    color: var(--blackColor);
    cursor: pointer;
    padding: 12px 16px;
    font-size: 13px !important;

    text-decoration: none;
    display: block;
    text-align: start
  }
  .dropdown-contentA a {
    color: var(--blackColor);
    cursor: pointer;
    padding: 12px 16px;
    font-size: 13px !important;

    text-decoration: none;
    display: block;
    text-align: start
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  

  .dropdown-contentA a:hover {background-color: #ddd;}
  
  .dropdownA:hover .dropdown-contentA {display: block;}
  


 .dropdown1 .dropdown-content1 a:hover {background-color: #ddd;}

.dropdown1:hover .dropdown-content1 {display: block !important;}

.dropdown2 .dropdown-content2 a:hover {background-color: #ddd;}

.dropdown2:hover .dropdown-content2 {display: block !important;}

.dropdown3 .dropdown-content3 a:hover {background-color: #ddd;}

.dropdown3:hover .dropdown-content3 {display: block !important;}

  
.D6_SL_Navbar_Link1N{
    
    color:#ffff !important;

    text-decoration: none;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    padding-bottom: 5px;
}

.D6_SL_Navbar_Link2 {
    
    border-bottom-width: 2px;
    text-decoration: solid;
    color: var(--redColor);
    /* text-decoration-color: var(--redColor); */
    font-weight: 600;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-style: solid;
    /* width: 50%; */
    padding-bottom: 5px;

}
.D6_SL_Navbar_Link2N {
    
    border-bottom-width: 2px;
    text-decoration: solid;
    color: var(--redColor);
    /* text-decoration-color: var(--redColor); */
    font-weight: 600;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-style: solid;
    /* width: 50%; */
    padding-bottom: 5px;

}
.filly_icon{
   cursor: pointer;
}
.icon_head{
    /* cursor: pointer;
    height: 50px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px; */

    cursor: pointer;
    height:70px;
    /* margin-top: -34px; */
}
.grid_ham{
display: none;
}
.icon_gridM2{
 display: none;
}


@media (max-width:500px) {
    .grid_ham{
display: block;
width: 50%;
align-self: center;
    }
    .icon_ham{
        color: var(--redColor);
        font-size: 26px;
        padding-left: 20px;
        margin-top: 2px;
        /* font-weight: 500 !important; */
        align-self: center;
    }
    .icon_grid{
        width: 50%;
        text-align: end;
        padding-right: 10px;
    }
    .icon_gridM{
        display: none;
    }
    .icon_gridM2{
        display: block !important;
        text-align: end;
        padding-right: 10px;
    }
    .header_strip{
      display: none;
    }
    .D6_SL_Navbar_Items {
        margin-top: 0px;
        width: 100%;
        background-color: white;
    }
    .D6_SL_Navbar_ItemsA {
        margin-top: 0px;
        width: 100%;
        background-color: white;

    }
    .icon_head{
        height: 44px;
        margin-top: 8px !important;
    }
    .Grid_MC{
        justify-content: center;
      }
     .icon_nav{
        padding-top: 5px;
        padding-bottom: 5px;
     }
      .Grid_TC{
          /* justify-content: center; */
          width: 100%;
      }
      .D6_SL_Navbar_Items1 {
        padding-bottom:10px !important;
    }
    .grid_cont{
        /* padding-top: 30px; */
        padding-left: 0px;
    }
    .grid_contSub{
        display: block !important;
    }
    .grid_multi{
       display: none;
    }
    .gird_nav{
        justify-content: center !important;
        padding-right: 0px;
    }
}