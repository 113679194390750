.bg_sub_nbfc{
    background:linear-gradient(450deg,transparent,transparent) ,url(../../../../images/nbfc-main.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    height: auto;
    background-attachment: fixed;
}

@media (max-width:500px) {
    
    .bg_sub_nbfc{
         background-size: 100% 500px;
            }
}