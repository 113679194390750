
.payment_contM{
    padding-top: 19vh !important;
    height: 100% !important;
}.ppr_payment{
    width: 100%;
    margin: 10px;
    height: 100%;
    text-align: -webkit-center;
}
.plan_head{
    font-size: 28px !important;
    font-weight: 700 !important;
    padding-bottom: 20px;
}
.back_btn{
    background: var(--redColor) !important;
    color: white !important;
    font-weight: 600 !important;
    width: 20%;
    padding: 6px !important;

}

@media (max-width:500px) {
    .payment_contM{
        padding-top: 1vh !important;
        margin-bottom: 1vh;
        height: 100% !important;
    }
    .plan_head {
        font-size: 21px !important;
        font-weight: 700 !important;
        padding-bottom: 20px;
    }
    .back_btn{
        margin-bottom: 20px;
    
    }
    .ppr_payment {
        margin: 0px;
        padding-top: 20px;

    }
}