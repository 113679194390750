.topA_bg{
    padding-top: 83px;
    height: 82vh;
    object-fit: cover;
    width: 100%;
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
    background: linear-gradient(45deg,rgba(0,0,0,0.6),transparent),url('../../../images/Cr1.jpg');
}
.bg_head{
    top: 40%;
    position: absolute;
    width: 65%;
    padding-left: 30px;
}
.bg_head h1{
    font-weight: 600 !important;
    font-size: 45px !important;
   color: var(--whiteColor);
   line-height: 2;
   
}
.topA_SubImg{
    width: 100%;
    background:url(../../../images/aboutSub.jpg ) ;
    background-repeat: no-repeat;
    height: 750px;

}
.contA_sub{
    position: absolute;
    /* top: 62%; */
    padding-top: 31px;
    width: 100%;
    text-align: -webkit-right;
}
.bg_head2{
    width: 54%;
    text-align: justify;
    padding: 20px;

}
.bg_head2 h2{
    color: var(--redColor);
    
}
.bg_head2 h1{
    font-size: 23px;
    padding-bottom: 20px;
}
.bg_head2 p{
    font-size: 17px;
    line-height: 1.7;
    text-align: justify;
}
.chip_about{
    background: var(--redColor) !important;
    color: white !important;
    font-weight: 600 !important;
    padding: 10px !important;
    /* margin-left: 20px !important; */
}

@media (max-width:500px) {
    .topA_bg{
        height: 65vh;
        padding-top: 83px;
        background-position: center !important; 
background-size: cover !important ;

        background-repeat: no-repeat;
        background: linear-gradient(45deg,rgba(0,0,0,0.6),transparent),url('../../../images/Cr1.jpg');
    }
    .bg_head {
        top: 34%;
        position: absolute;
        width: 100%;
        padding-left: 0px;
        text-align: center;
    }
    .bg_head h1 {
       
        font-size: 30px !important;
      
    }
    .topA_SubImg {
        width: 100%;
        background: none;
        min-height: 750px;
        height: auto !important;
    }
    .contA_sub {
        position: relative;
      
    }
    .bg_head2 {
        width: 100%;
        text-align: justify;
        padding: 0px;
    }
    .bg_head2 h2 {
        color: var(--redColor);
        font-size: 18px;
        padding: 10px;
    }
    .bg_head2 p {
        font-size: 16px;
        line-height: 1.7;
        text-align: justify;
        padding: 10px;

    }
}