.wcu_head{
    padding-top: 5vh;
    font-size: 35px !important;
    text-align: center;
    color: var(--redColor);
    font-weight: 600 !important;
}
.wcu_ppr{
    /* background: var(--redColor) !important; */
    min-height: 150px;
    margin: 20px;
    padding: 10px;
    box-shadow: 0px 3px 5px -1px rgb(208 0 0 / 20%), 0px 6px 10px 0px rgb(208 0 0 / 14%), 0px 1px 18px 0px rgb(208 0 0 / 12%) !important;

}
.wcu_ppr:hover{
    border-style: solid;
    border-color: var(--redColor);
    border-width: 1px;
    cursor: pointer;
}
.wcu_text{
    padding-top: 5vh;
    text-align: center;
    color: var(--redColor);
    font-weight: 600 !important;
    font-size: 16px !important;
}