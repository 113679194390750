.text_doc{
    font-size: 18px !important;

}
.head_doc{
    font-size: 25px !important;
    font-weight: 500 !important;
}
.acc_doc{
margin-top: 13vh !important;
}
.ppr_documents{
    min-height: 100px;
    max-width: 250px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
    padding-top: 35px;
    padding-bottom: 10px;
    color: var(--redColor) !important;
}

@media (max-width:500px) {
    .acc_doc {
        margin-top: 5vh !important;
    }
}