.signIN_bg{
background: linear-gradient(rgba(0,0,0,0.8),transparent), url(../../../images/login.jpg);
background-position: top center;
background-repeat: no-repeat;
background-size: cover;
min-height: 100vh;
height: auto;
margin-bottom: 5px;
background-attachment: fixed;;
}
.signIN_head{
    padding-top: 25vh;
    padding-bottom: 25px;
    color: white;
    font-size: 45px !important;
    text-align: center;
    font-weight: 600 !important;
}
.phone_head1{
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 24px !important;
}
.signIN_head1 {
    padding-top: 5vh;
    padding-bottom: 20px;
    color: black;
    font-size: 35px !important;
    text-align: center;
    font-weight: 600 !important;
}
.signIN_ppr{
    min-height: 270px;
    min-width: 300px;
    max-width: 480px;
    border-radius: 20px !important;
}
.phone_head{
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 24px !important;
}

.btn_signin{
    background: var(--redColor) !important;
    color: white !important;
    width: 48%;
    height: 40px;
    margin-top: 25px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}
.new_customer{
    padding-top: 25px;
    font-size: 20px !important;
    font-weight: 400 !important;
    cursor: pointer;
    
}
.new_customer1{
    padding-top: 25px;
    font-size: 14px !important;
    font-weight: 400 !important
}
.link_register{
    border-bottom: solid;
    border-bottom-color: var(--redColor);
        margin-left: 6px;
        cursor: pointer;

}
.ppr2{
    width: 400px;
    padding: 30px;
    border-radius: 20px !important;
}
.ppr21{
    min-width: 275px;
    padding: 30px;
    border-radius: 20px !important;
}

@media (max-width:500px) {
    .ppr2 {
        max-width: 350px;
        width: auto;

    }
    
}