
.ppr_HIW{
    width: 100%;
    text-align: center;
    padding: 10px;
    margin: 10px;
}

.head_HIW{
    padding-top: 20px;
    font-size: 30px !important;
    font-weight: 600 !important;
    color: var(--blackColor);
    text-align: center;

}
.icon_HIW{
height: 80px;
}

.subHead_HIW{
    padding-top: 10px;
    padding-bottom: 5px;
}