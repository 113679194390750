.mv_bg{
    
    margin-bottom: 40px;
    margin-top: 20px;
}
.mv_head{
    font-size: 30px !important;
    color: var(--blackColor);
    text-align: start;
    margin-top: 3vh !important;
    border-bottom: solid;
    border-bottom-color: var(--redColor);
    padding-bottom: 5px;
    width: 60%;
}
.mv_text{
    color: var(--blackColor);
    font-size: 17px !important;
    line-height: 2 !important;
    padding-top: 15px;
    text-align: justify;
    padding-right: 35px;
}
.home_icon {
    height: 90px;
  
}
.homeIcon_text  {font-size: 19px !important;
font-weight: 600! important;
color: black;}
.keyS_ppr {
    min-height: 130px;
    max-width: 250px;
    margin: 20px;
    padding: 10px;
    box-shadow: 0px 3px 5px -1px rgb(208 0 0 / 20%), 0px 6px 10px 0px rgb(208 0 0 / 14%), 0px 1px 18px 0px rgb(208 0 0 / 12%) !important;
}
.keyS_ppr:hover{
    transform: scale(1.2);
    transition: transform 2s;
    cursor: pointer;
    background-color: var(--redColor);
}
.keyS_ppr:hover .wcu_text{
    color: white;
}
.chip_PayC{
    padding: 5px;
    background-color: var(--redColor) !important;
    color: white !important;
    font-weight: 700 !important;
    cursor: pointer !important;
}

.strike_amount{
    text-decoration: line-through;
    padding: 5px;
    color: grey;

    font-weight: 500 !important;
}
.discount{
    padding: 5px;
   
    font-weight: 700 !important;
}
.Dialog_Signin .MuiDialog-paper{
    margin:0px !important
    }
    .Dialog_Signin .MuiDialogContent-root{
        padding: 0px !important;
    }

 .mv_mainHead{
    font-size: 20px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-weight: 600 !important;
 }