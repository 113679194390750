.bg_articles{
    
    height: auto;
    min-height: 78vh !important;
    width: 100% !important;
    background:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('../../images/articles.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    align-content: center;
  
}
.articles_head{
    margin-top: 50px !important;
    margin-bottom: 20px !important;
    text-align: center;
    color: var(--redColor);
    font-size: 30px !important;
    font-weight: 700 !important;
}
.articles_text{
    text-align: center;
    font-size: 20px !important;
    color: var(--blackColor);
}
.bgS_textArticle{
    padding-top: 38vh !important;
    color: white;
    padding: 30px;
    font-weight: 600 !important;
    font-size: 45px !important;

    text-align: center;
}


@media (max-width:500px) {
    
    .bg_articles{
        min-height: 70vh !important;
            }
     .bgS_textArticle{
           padding-top: 38vh !important;
                color: white;
                padding: 30px;
                font-weight: 600 !important;
                font-size: 40px !important;
            
                text-align: center;
            }        
}